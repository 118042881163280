<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item prop="node_name" :label="__('Name')">
            <el-input v-model="nodeToBind.node_name" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            prop="goto"
            :label="__('Goto Node')"
            class="is-required"
          >
            <goto-node-condition
              v-model="gotoCondition"
              condition-keyword="Goto"
              :allow-create="false"
              :is-target-editable="isTargetEditable"
              :goto-options="generateGotoOptions"
              style="width: 100%;"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "../BaseNode";
import _ from "lodash";
import { NODE_CATEGORIES, NODE_TYPES } from "@/constants/nodes";
import GotoNodeCondition from "../components/GotoNodeCondition";
// import nodeTypes from "@/store/modules/nodeTypes";

export default {
  mixins: [BaseNode],
  components: {
    GotoNodeCondition
  },
  data() {
    const validateGotoNode = (rule, value, callback) => {
      const gotoNode = this.nodeToBind.goto_node.data;
      if (!gotoNode) {
        callback(__("Goto node cannot be empty"));
      } else {
        if (gotoNode.goto_node_id === -1 && !gotoNode.goto_node_name) {
          callback(__("Goto node cannot be empty"));
        } else if (gotoNode.node_id === -1) {
          !_.map(this.getValidNodes, "node_name").includes(
            gotoNode.goto_node_name
          )
            ? callback()
            : callback(__("Node exists"));
        } else {
          callback();
        }
      }
    };
    return {
      rules: {
        goto: {
          validator: validateGotoNode,
          trigger: "change"
        }
      }
    };
  },
  computed: {
    gotoCondition: {
      get: function() {
        return _.isEmpty(this.nodeToBind.goto_node.data)
          ? {}
          : {
              keyword: "Goto",
              node_name: this.nodeToBind.goto_node.data.goto_node_name,
              node_id: this.nodeToBind.goto_node.data.goto_node_id,
              msg: "",
              error: "",
              keyword_error: ""
            };
      },
      set: function(gotoCondition) {
        if (
          // append goto node to keyword conditions only if goto node content changes
          !_.isEqual(this.gotoCondition, gotoCondition)
        ) {
          this.$set(
            this.nodeToBind.goto_node.data,
            "goto_node_id",
            gotoCondition.node_id
          );
          this.$set(
            this.nodeToBind.goto_node.data,
            "goto_node_name",
            gotoCondition.node_name
          );
        }
      }
    },
    generateGotoOptions() {
      const nodeToUse = !this.isEditing ? this.node : this.nodeToBind;
      return _.filter(
        this.getValidNodes,
        // orphans will have the root of the current canvas also,
        // so the options should not have the current root
        gotoNodeOption =>
          (gotoNodeOption.node_id !== nodeToUse.node_id ||
            (!this.addNewNodeBelow && !this.attemptConvertSameNodeToGoto)) &&
          gotoNodeOption.node_type.data.category !== NODE_CATEGORIES.SYSTEM
      );
    },
    isTargetEditable() {
      return Boolean(
        _.get(this.nodeToBind, "goto_node.data.goto_target_editable", 1)
      );
    }
  },
  methods: {
    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);

      return nodeToCleanUp;
    },
    cleanUpNode() {
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    }
  },
  created() {
    if (!this.nodeToBind.node_id || _.isEmpty(this.nodeToBind.goto_node)) {
      this.$set(this.nodeToBind, "goto_node", {});
      this.$set(this.nodeToBind.goto_node, "data", {});

      this.$set(this.nodeToBind, "node_type", NODE_TYPES.GOTO.NODE_TYPE);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node_common.scss";
.tabPane {
  max-height: 52vh;
  padding-right: 30px;
}
</style>
